export enum TypeGame {
  Bingo = 1,
}
export enum AnswerType {
  text = 1,
  image = 2,
  math_formula = 3,
}

export interface QuestionsBingoDescriptionInterface {
  text?: string;
  video?: string;
  math_formula?: string;
  image?: any | File;
  imgUrl?: string;
}

export interface AnswerBingoDescriptionInterface {
  type?: AnswerType;
  text?: string;
  video?: string;
  image?: any | File;
  imgUrl?: string;
  math_formula?: string;
  is_correct?: boolean | number;
  id?: number;
  answer?: string;
}

export interface QuestionsBingoInterface {
  number?: number;
  id?: number;
  description: QuestionsBingoDescriptionInterface;
  analysis_time?: number;
  analysis_left?: number;
  answers: AnswerBingoDescriptionInterface[];
  answer_time: number;
  answer_left?: number;
}

export interface NewBingoResponseInterface {
  id: number;
}

export interface NewBingoInformationInterface {
  id?: number;
  type?: TypeGame;
  title: string;
  description: string;
  card_length: number;
  card_width: number;
  created_by?: string;
  created_by_email?: string;
  total_players?: number;
  question_count?: number;
  imgUrl?: any;
  image?: any;
  share_link?: string;
  public_favourite?: boolean;
  is_my?: boolean;
}

export enum StepAddBingo {
  information = 0,
  options = 1,
  preview = 2,
}

export interface AddBingoStateInterface {
  step: StepAddBingo;
  newBingoInformation: NewBingoInformationInterface;
  newBingoOptions: QuestionsBingoInterface[];
  lastQuestions: QuestionsBingoInterface[];
  isChangeLength: boolean;
}
